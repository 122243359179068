<template>
    <mnSup mostrar="true" />
    <main id="eval-login">
        <section id="login">
            <img src="assets/img/login-tit.svg" alt="">
            <form @submit.prevent="loginUser">
                <p>*Acceso solo para evaluadores</p>
                <div class="boxItem">
                    <div class="formItem">
                        <input type="email" name="mail" placeholder="correo" v-model="form.email">
                    </div>
                </div>
                <div class="boxItem">
                    <div class="formItem">
                        <input type="password" name="pass" placeholder="contraseña" v-model="form.pass">
                    </div>
                </div>
                <div class="boxSubmit">
                    <button class="btn">INGRESAR</button>
                </div>
                <div class="msg" v-if="msg!=''">{{msg}}</div>
                <div class="forgetPass">
                    <button @click="modalPass('pass')" type="button">Reestablecer mi contraseña</button>
                </div>
            </form>
            
        </section>
    </main>
    <div class="wrap">
        <p style="text-align:center; margin:auto;">Si requiere ayuda extra puede enviar un correo a: <a href="mailto:soportecongresoexplora@incode.cl">soportecongresoexplora@incode.cl</a></p>
    </div>
    <footerLogo />
    <modal modal="password"/>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex';
import CryptoJS from 'crypto-js';
import mnSup from '@/components/mnSup.vue'
import footerLogo from '@/components/footerLogo.vue'
import modal from '@/components/modal.vue'


export default {
    name: 'Login',
    components: {
        mnSup, footerLogo, modal
    },
    data(){
        return{
            form:{
                email:'',
                pass:''
            },
            msg:''
        }
    },
    created(){
        document.title = 'LOGIN - XXI Congreso Nacional Explora'
    },
    methods:{
        ...mapMutations(['showModal']),
        async loginUser(){
            let pass = this.form.pass;

            let encTxt = CryptoJS.SHA1(pass.trim())
            //let words = CryptoJS.enc.Utf8.parse(encTxt)
            //let passsha = CryptoJS.enc.Utf8.stringify(encTxt)
            let passsha = encTxt.toString()


            let url = `${this.apiURL}/api/?clase=MasterData&metodo=login&parametros={"username":"${this.form.email}","password":"${passsha}"}`
            try{
                let rs = await fetch(url);
                //let data = await rs.json();
                let datosRaw = await rs.arrayBuffer();
                let decoder = new TextDecoder("ISO-8859-1");
                let datosDec = decoder.decode(datosRaw);
                let data = JSON.parse(datosDec);

                if(data.resultado){
                    localStorage.setItem("session", JSON.stringify(data.salida));
                    window.location.href = '/evaluaciones'
                }else{
                    console.warn('error:', data.error)
                    this.msg = 'Credenciales incorrectas, intente nuevamente'
                    setTimeout(()=>{
                        this.msg = ''
                    }, 3000)
                    
                    return
                }
            }catch(e){
                console.log(e);
            }
        },
        modalPass(modal){
            this.showModal();
            console.log('Abriendo modal recuperar contraseña');
            
        }
    },
    computed:{
        ...mapState(['apiURL', 'loged']),
    }
}
</script>

<style lang="scss" scoped>
main#eval-login{
    background-image: url('../../public/assets/img/eval-fondo.svg');
    background-position: bottom center;
    background-repeat: no-repeat;

    display: flex;
    justify-content: center;
    align-items: center;

    height: calc(90vh - 120px);
    width: 95vw;
    margin: auto;

    margin-bottom:20px;

    section#login{
        display: grid;
        grid-template-rows: 90px 1fr;
        gap:20px;
        &>img{
            height: 100%;
            width: auto;
            justify-self: center;
        }
        form{
            background-color: #fff;
            padding:20px;
            border-radius: 30px;
            border:2px solid #000;
            max-width: 310px;
            justify-self: center;

            p{
                font-size: .8rem;
                margin-bottom: 15px;
            }
            .formItem{
                input{
                    border-radius: 50px;
                    background-color: var(--color1);
                    padding:15px 20px;
                    border: 2px solid var(--color1);
                    &::placeholder{
                        color:#000;
                    }
                    &:focus{
                        border: 2px solid var(--color2);
                        box-shadow: none;
                    }
                }
                
            }
            .msg{
                font-size: .9em;
                color:red;
            }
            .boxSubmit{
                text-align: center;
                margin-bottom: 5px;
                padding-top:5px;
                button{
                    background-color: var(--color2);
                    color: #fff;
                    border-radius: 50px;
                }
            }
            .forgetPass{
                text-align: center;
                button{
                    font-size: .8rem;
                    background: transparent;
                    cursor: pointer;
                    color:var(--color2);
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
</style>